import React from 'react';
import { css } from '@emotion/core';

import { InView } from 'react-intersection-observer';
import gsap from 'gsap';
import Image from 'gatsby-image';
import { HEADING_LARGE, P } from '../elements/typography';

import NavLink from './nav-link';
import { useRef } from 'react';
import { useEffect } from 'react';

const NextProject = ({ slug, title, client, featuredImagePortrait }) => {
  const nextUrl = `/work/${slug}/`;
  const imageRef = useRef(null);
  const textRef = useRef(null);

  const handleReveal = (inView, entry) => {
    if (inView) {
      const el = entry.target.firstElementChild;
      const tl = new gsap.timeline();

      tl.to(el, {
        duration: 1.3,
        ease: 'power3.out',
        backgroundColor: 'black',
      });
    }
  };

  const mouse = {
    bounds: null,
    x: 0,
    y: 0,
  };
  let requestId = null;

  useEffect(() => {
    gsap.set(imageRef.current, {
      xPercent: -50,
      yPercent: -50,
      scale: 0,
      x: '50%',
      y: '50%',
    });
  }, []);

  function onMouseMove(event) {
    if (mouse.bounds == null) {
      mouse.bounds = textRef.current.getBoundingClientRect();
    }

    mouse.x = event.clientX;
    mouse.y = event.clientY;

    if (!requestId) {
      requestId = requestAnimationFrame(updateFollower);
    }
  }

  function onMouseLeave() {
    gsap.to(imageRef.current, 0.3, {
      x: mouse.x,
      y: mouse.y,
      scale: 0,
    });
  }

  function updateFollower() {
    gsap.to(imageRef.current, 0.3, {
      x: mouse.x,
      y: mouse.y,
      scale: 1,
    });
    requestId = null;
  }

  return (
    <InView
      threshold={0.5}
      triggerOnce={true}
      onChange={(inView, entry) => handleReveal(inView, entry)}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100vw;
          min-height: 85vh;
          position: relative;

          overflow: hidden;
          background: #000000;
          padding: 0;
          @media (min-width: 768px) {
            flex-direction: row;
            padding: 32px;
          }
          h2 {
            color: white;
            text-align: center;
            overflow: hidden;
            span {
              display: block;
              border-bottom: 0.08em solid #ffffff;
              margin-bottom: 0.15em;
              padding-bottom: 0.15em;
            }
            &:hover {
              .gatsby-image-wrapper {
                transform: scale(1.2);
                opacity: 1;
              }
            }
          }

          .gatsby-image-wrapper {
            transition: transform 6000ms linear;
            opacity: 1;
          }
        `}
      >
        <NavLink url={nextUrl}>
          <div
            ref={textRef}
            onMouseMove={e => onMouseMove(e)}
            onMouseLeave={e => onMouseLeave(e)}
            role={'link'}
            tabIndex={0}
            css={css`
              padding: 16px;
              outline: none;
            `}
          >
            <P
              css={css`
                color: white;
                text-align: center;
                text-transform: uppercase;
                margin-bottom: 32px;
              `}
            >
              (Next Project)
            </P>
            <HEADING_LARGE>
              <span>{client}</span>
              <span>{title}</span>
            </HEADING_LARGE>
          </div>
        </NavLink>
        {featuredImagePortrait && (
          <div
            ref={imageRef}
            css={css`
              position: absolute;
              left: 0;
              top: 0;
              width: 300px;
              pointer-events: none;
              display: none;
              @media (min-width: 768px) {
                display: block;
              }
            `}
          >
            <Image
              fluid={featuredImagePortrait.fluid}
              alt={featuredImagePortrait.alt}
            />
          </div>
        )}
      </div>
    </InView>
  );
};

export default NextProject;
