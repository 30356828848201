import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Footer from '../components/footer';
import { css } from '@emotion/core';
import { setColorMode } from '../utilities/functions';
import HeroText from '../components/hero-text';
import ContentEl from '../components/element.js';
import NextProject from '../components/project-next';
import { Container } from '../elements/layouts';
import { P } from '../elements/typography';

const ProjectPage = ({ data: { project, nextProject }, location }) => {
  const { content, headlineNode, categories } = project;

  useEffect(() => {
    setColorMode(location.pathname);
  }, [location.pathname]);
  const { htmlAst } = headlineNode.childMarkdownRemark;
  return (
    <>
      {htmlAst && <HeroText headline={htmlAst} />}
      <Container>
        {categories.length !== 0 && (
          <div
            css={css`
              max-width: 600px;
              margin: 0 auto 32px auto;
              text-align: center;
              text-transform: uppercase;
              p {
                display: inline-block;
                padding: 0 8px;
              }
            `}
          >
            {categories.map((category, i) => {
              return (
                <React.Fragment key={category.id}>
                  <P>{category.categoryName}</P>{' '}
                  {i < categories.length - 1 && '/'}
                </React.Fragment>
              );
            })}
          </div>
        )}
        {content && content.map(el => <ContentEl key={el.id} el={el} />)}
      </Container>
      <NextProject {...nextProject} />
      <Footer pathname={location.pathname} />
    </>
  );
};

export default ProjectPage;

export const query = graphql`
  query ProjectPageQuery($slug: String!, $nextSlug: String!) {
    nextProject: datoCmsProject(slug: { eq: $nextSlug }) {
      slug
      title
      client

      featuredImagePortrait {
        alt
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    project: datoCmsProject(slug: { eq: $slug }) {
      id
      title
      client
      headlineNode {
        childMarkdownRemark {
          htmlAst
        }
      }
      categories {
        categoryName
        id
      }
      featuredImagePortrait {
        fluid(maxWidth: 800) {
          ...GatsbyDatoCmsFluid
        }
      }
      featuredImageLandscape {
        fluid(maxWidth: 1600) {
          ...GatsbyDatoCmsFluid
        }
      }
      content {
        ... on DatoCmsImageGrid {
          id
          images {
            alt
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        ... on DatoCmsSingleImage {
          id
          alignment

          image {
            alt
            fluid(maxWidth: 1600) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        ... on DatoCmsTextBlock {
          id
          heading
          paragraph
        }
        ... on DatoCmsVideoBlock {
          id
          video {
            width
            height
            providerUid
          }
        }
        ... on DatoCmsTextContent {
          id
          textAlign
          alignment
          alignmentY
          width
          height
          textNode {
            childMarkdownRemark {
              htmlAst
            }
          }
        }
      }
    }
  }
`;
